import Select from 'react-select';
import useAuth from 'hooks/useAuth';
import CategoriaEdit from '../categorias/edit';
import baseController from 'api/base.controller';
import Error401 from 'components/pages/errors/Error401';
import React, { useState, useEffect } from 'react';
import { cardapioRoles } from '../sistema/allowedRoles';
import { useNavigate, useParams } from "react-router-dom";
import FullScreenLoading from '../sistema/fullScreenLoading';
import { Card, Form, Button, Modal, Accordion } from 'react-bootstrap';
import FieldValidationMessage from '../sistema/fieldValidationMessage';
import handleDecrypt from 'components/pages/sistema/handleDecrypt';

function adjustDataToCombo(data) {
  return data.map((categoria) => {
    return {
      value: categoria.id,
      nome: categoria.nome,
      exibicao: categoria.exibicao,
      produtos: categoria.produtos,
      label: categoria.nome
    }
  })
}

function adjustLojaDataToCombo(data) {
  return data.map((loja) => {
    return adjustUniqueLojaDataToShow(loja);
  });
}

function adjustUniqueLojaDataToShow(loja) {
  return {
    value: loja.id,
    nome: loja.nome,
    label: loja.nome,
  }
}

export default function CardapioEdit({ isModal, handleCardapioModalSubmit, setModalShow, className }) {
  const { id } = useParams();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [valueLoja, setValueLoja] = useState(null);
  const [validated, setValidated] = useState(false);
  const [comboValues, setComboValues] = useState([]);
  const [authorized, setAuthorized] = useState(false);
  const [categoriaId, setCategoriaId] = useState(null);
  const [comboLojaValues, setComboLojaValues] = useState([]);
  const [produtoDuplicado, setProdutoDuplicado] = useState(false);
  // const [isClienteMensal, setIsClienteMensal] = useState(false);
  // const [isUtilizaEstoque, setIsUtilizaEstoque] = useState(false);
  const [categoriaModalShow, setCategoriaModalShow] = useState(false);
  const [editCategoriaModal, setEditCategoriaModal] = useState(false);
  const [isCliente] = useState((handleDecrypt(localStorage.getItem("role")) === "CM-C" || handleDecrypt(localStorage.getItem("role")) === "CM-A"));
  const [data, setData] = useState({
    id: "",
    nome: "",
    lojaId: null,
    categorias: [],
  });

  useEffect(async () => {
    const isAuthorized = cardapioRoles.includes(auth.role ?? JSON.parse(localStorage.getItem('auth')).role);

    setAuthorized(isAuthorized);

    if(isAuthorized) getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    let cardapioRes;

    if (id && !isModal) {
      cardapioRes = await baseController.get(`Cardapio/${id}`, {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      });

      setData(cardapioRes.data);
      setValue(adjustDataToCombo(cardapioRes.data.categorias))
      setValueLoja(adjustUniqueLojaDataToShow(cardapioRes.data.loja));
    }

    if (isCliente && !id) {
      const newData = { ...data };

      const defaultLoja = await baseController.get('Loja/GetId/ClienteMensal', {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      });

      newData.lojaId = defaultLoja.data.id;

      setData(newData);
    } else {
      const lojas = await baseController.get('Loja/GetAll', {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      })
      
      setComboLojaValues(adjustLojaDataToCombo(lojas.data));
    }

    const categorias = await baseController.get(`Categoria/GetAll?lojaId=${cardapioRes?.data?.lojaId ?? ''}`, {
      headers: {
        'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
      }
    });
      
    setComboValues(adjustDataToCombo(categorias.data));

    setLoading(false);
  };

  useEffect(() => {
    const categorias = [];
    const newData = {...data};

    value?.forEach((categoria) => {
        categorias.push({ id: categoria.value, nome: categoria.nome, exibicao: categoria.exibicao, produtos: categoria.produtos });
    });

    newData['categorias'] = categorias;
    setData(newData);
  }, [value]);

  useEffect(async () => {
    let id = null;
    const newData = {...data};

    if (valueLoja !== null && valueLoja.value !== null) id = valueLoja.value;

    newData['lojaId'] = id;

    const categorias = await baseController.get(`Categoria/GetAll?lojaId=${id ?? ''}`, {
      headers: {
        'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
      }
    });
      
    setComboValues(adjustDataToCombo(categorias.data));

    setData(newData);
  }, [valueLoja]);

  const checkProductsNames = () => {
    const names = data.categorias.map(categoria => {      
      return categoria.produtos.map(produto => produto.nome)
    }).flat();

    const uniqueNames = new Set(names);

    if (uniqueNames.size !== names.length) {
      setProdutoDuplicado(true);
      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    
    const form = event.currentTarget;
    const productsValid = checkProductsNames();
    
    if (!form.checkValidity() || !productsValid) {
      event.stopPropagation();
      
      setValidated(true);
      setLoading(false);

      return;
    }

    if (isModal) {
      await baseController.post('Cardapio', data, {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      })
        .then((res) => handleCardapioModalSubmit(event, res.data))
        .finally(() => setLoading(false));
    } else if (id) {
      await baseController.put('Cardapio', data, {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      })
        .then(() => navigate(-1))
        .finally(() => setLoading(false))
    } else {
      await baseController.post('Cardapio', data, {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      })
        .then(() => navigate(-1))
        .finally(() => setLoading(false));
    }
  };

  const handleCategoriaModalSubmit = async (event, categoria) => {
    setLoading(true);
    event.preventDefault();

    editCategoriaModal ? updateCategoria(categoria) : addCategoria(categoria);
    
    setEditCategoriaModal(false);
    setCategoriaModalShow(false);
    setLoading(false);
  };

  const addCategoria = (categoria) => {
    const newData = { ...data };

    newData['categorias'].push(categoria);

    setData(newData);
    setValue(adjustDataToCombo(newData['categorias']));
  };

  const updateCategoria = (categoria) => {
    const newData = { ...data };

    const index = newData['categorias'].findIndex(cat => {
      return cat.id === categoria.id
    });

    newData['categorias'][index] = categoria;

    setData(newData);
    setValue(adjustDataToCombo(newData['categorias']));
  };
  
  const handleData = (event) => {
    const newData = {...data};

    newData[event.target.name] = event.target.value;

    setData(newData);
  };

  if(loading) return (<FullScreenLoading />);

  if(!authorized) return (<Error401 />);

  return (
    <Card className={className ? className + 'edit-card' : 'edit-card'}>
      <Card.Header>
        <h1 className='edit-form-title'>
          { id & !isModal ? 'Editar cardápio' : 'Incluir cardápio'}
        </h1>
      </Card.Header>
      <Card.Body
        as={Form}
        noValidate
        id='cardapio-form'
        validated={validated} 
        onSubmit={handleSubmit}
        className='edit-card-body'
      >
        <Form.Group 
          className="mb-3"
          controlId="formGroupName"
        >
          <Form.Label>Nome</Form.Label>
          <Form.Control
            required
            name="nome"
            type="text"
            minLength={2}
            maxLength={200}
            value={data.nome}
            placeholder="Nome do cardápio"
            onChange={(e) => handleData(e)}
          />
          <Form.Control.Feedback type="invalid">
            <FieldValidationMessage
              minLength={2}
              maxLength={200}
              fieldName={"Nome"}
            />
          </Form.Control.Feedback>
        </Form.Group>
        { !isCliente ? 
          <Form.Group 
            controlId="formLojaId"
            className="mb-3 form-group-select-with-button"
          >
            <Form.Label>Loja</Form.Label>
            <Select
              name="lojaId"
              isMulti={false}
              value={valueLoja}
              closeMenuOnSelect
              options={comboLojaValues}
              classNamePrefix="react-select"
              placeholder="Selecione a loja"
              onChange={value => setValueLoja(value)}
              className={validated && data.lojaId === null ? 'form-control is-invalid select-loja-event-form' : 'select-pessoas-loja-form'}
            />
            <Form.Control.Feedback type="invalid">
              <FieldValidationMessage
                fieldName={"Loja"}
              />
            </Form.Control.Feedback>
          </Form.Group> : <></>
        }
        <Form.Group 
          controlId="formCategorias"
          className="mb-3 form-group-select-with-button"
        >
          <Button 
            className="form-select-button"
            onClick={() => setCategoriaModalShow(true)}
          >
            Nova Categoria
          </Button>
          <Form.Label>Categorias</Form.Label>
          <Select
            isMulti
            value={value}
            name="categorias"
            options={comboValues}
            closeMenuOnSelect={false}
            classNamePrefix="react-select"
            onChange={value => setValue(value)}
            placeholder='Selecione as categorias'
          />
        </Form.Group>
        {
          validated && produtoDuplicado &&
          <div className='duplicated-product-container'>
            <p className='duplicated-product-message'>
              Existem produtos com nomes duplicados no cardápio, remova os produtos duplicados.
            </p>
          </div>
        }
        <Accordion>
        {data.categorias.map((categoria) => {
            return (
              <Accordion.Item 
                key={categoria.id}
                eventKey={categoria.id}
              >
                <Accordion.Header>{ categoria.exibicao }</Accordion.Header>
                <Accordion.Body className='edit-cardapio-accordion-body'>
                  <Button 
                    style={{width: '100%'}}
                    onClick={() => { 
                      setEditCategoriaModal(true); 
                      setCategoriaModalShow(true); 
                      setCategoriaId(categoria.id);
                    }}
                  >
                    Editar Categoria
                  </Button>
                  {categoria.produtos.map((produto) => {
                    return (
                      <Card 
                        key={produto.id} 
                        className='cardapio-detail-produto-card'
                      >
                        <Card.Img 
                          variant='top' 
                          src={produto.imagem} 
                          className='cardapio-detail-produto-card-image'
                        />
                        <Card.Body className='cardapio-detail-produto-card-body'>
                            <Card.Title>{produto.nome}</Card.Title>
                            <Card.Text>{produto.descricao}</Card.Text>
                        </Card.Body>
                      </Card>
                    )
                  })}
                </Accordion.Body>
              </Accordion.Item>
            )
          })}
        </Accordion>
      </Card.Body>
      <Card.Footer className='edit-buttons-wrapper'>
        <Button 
          variant="danger"
          onClick={() => {
            !isModal ? navigate(-1) : setModalShow(false);
          }}
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          variant="primary"
          form="cardapio-form"
        >
          Salvar
        </Button>
      </Card.Footer>
      <Modal
        centered
        size="lg"
        show={categoriaModalShow}
        onHide={() => setCategoriaModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <CategoriaEdit 
          isModal={true}
          categoriaId={categoriaId}
          className="form-in-modal "
          isEdit={editCategoriaModal}
          setModalShow={setCategoriaModalShow}
          handleModalSubmit={(event, data) =>  handleCategoriaModalSubmit(event, data)} 
        />
      </Modal>
    </Card>
  )
}